import React from "react"

const Header = () => {
    return (
        <header className="cd-header flex flex-column flex-center">
        <div className="text-component text-center">
            <h1>FAQ Template</h1>
            <p>👈 <a className="cd-article-link" href="https://codyhouse.co/gem/css-faq-template">Article &amp; Download</a></p>
        </div>
        </header>
    )
}

export default Header